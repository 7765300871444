import { format, parse } from "date-fns";
import { Entry } from "./client/models"

export const parseDate = (date: string) => {
    return parse(date, "yyyy-MM-dd' T'HH:mm:ss.SSS", new Date());
}
export const formatDate = (date: Date) => {
    return format(date, "yyyy-MM-dd' T'HH:mm:ss.SSS");
}

export type Weaken<T, K extends keyof T> = {
    [P in keyof T]: P extends K ? any : T[P]
}

// export const convertEntryStoreToEntry = (entry: Entry): Entry => {
//     console.log(entry.conversionDateTime);
//     const cdt: Date = parseDate(entry.conversionDateTime);
//     const entry = { ...entry, ...{ conversionDateTime: cdt } };
//     return entry
// }
