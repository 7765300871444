/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreRegularHoliday
 */
export interface StoreRegularHoliday {
    /**
     * 
     * @type {boolean}
     * @memberof StoreRegularHoliday
     */
    sunday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreRegularHoliday
     */
    saturday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreRegularHoliday
     */
    tuesday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreRegularHoliday
     */
    wednesday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreRegularHoliday
     */
    thursday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreRegularHoliday
     */
    friday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreRegularHoliday
     */
    holiday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreRegularHoliday
     */
    monday: boolean;
}

/**
 * Check if a given object implements the StoreRegularHoliday interface.
 */
export function instanceOfStoreRegularHoliday(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sunday" in value;
    isInstance = isInstance && "saturday" in value;
    isInstance = isInstance && "tuesday" in value;
    isInstance = isInstance && "wednesday" in value;
    isInstance = isInstance && "thursday" in value;
    isInstance = isInstance && "friday" in value;
    isInstance = isInstance && "holiday" in value;
    isInstance = isInstance && "monday" in value;

    return isInstance;
}

export function StoreRegularHolidayFromJSON(json: any): StoreRegularHoliday {
    return StoreRegularHolidayFromJSONTyped(json, false);
}

export function StoreRegularHolidayFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreRegularHoliday {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sunday': json['sunday'],
        'saturday': json['saturday'],
        'tuesday': json['tuesday'],
        'wednesday': json['wednesday'],
        'thursday': json['thursday'],
        'friday': json['friday'],
        'holiday': json['holiday'],
        'monday': json['monday'],
    };
}

export function StoreRegularHolidayToJSON(value?: StoreRegularHoliday | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sunday': value.sunday,
        'saturday': value.saturday,
        'tuesday': value.tuesday,
        'wednesday': value.wednesday,
        'thursday': value.thursday,
        'friday': value.friday,
        'holiday': value.holiday,
        'monday': value.monday,
    };
}

