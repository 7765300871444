import { useState, useEffect } from 'react'
import { formatDate, Weaken } from "../utils";
import {
    EntryItem,
    Form,
    FormField,
} from '../client/models';

// date-fns
import addHours from 'date-fns/addHours';
import startOfTomorrow from 'date-fns/startOfTomorrow';

import * as _ from 'lodash';

export interface EntryValue extends Weaken<EntryItem, 'value'> {
    value: string | Date
}

const createContents = (fields: FormField[]): EntryValue[] => {
    if (fields.length === 0) {
        return [];
    }
    const contentArray = _.map(fields, (f) => {
        return createContentArray(f);
    });
    const result: EntryValue[] = _.flatten(contentArray);
    return result;
}

const createContentArray = (field: FormField): EntryValue[] => {
    if (field.type === 'name') {
        return [{
            fieldId: field.id,
            fieldName: 'last_name',
            fieldLabel: '姓',
            fieldType: field.type,
            value: ''
        }, {
            fieldId: field.id,
            fieldName: 'first_name',
            fieldLabel: '名',
            fieldType: field.type,
            value: ''
        }]
    } else if (field.type === 'address') {
        return [{
            fieldId: field.id,
            fieldName: 'zip',
            fieldLabel: '郵便番号',
            fieldType: field.type,
            value: ''
        }, {
            fieldId: field.id,
            fieldName: 'prefecture',
            fieldLabel: '都道府県',
            fieldType: field.type,
            value: ''
        }, {
            fieldId: field.id,
            fieldName: 'city',
            fieldLabel: '市区町村',
            fieldType: field.type,
            value: ''
        }, {
            fieldId: field.id,
            fieldName: 'street',
            fieldLabel: '住所詳細',
            fieldType: field.type,
            value: ''
        }]
    } else if (field.type.includes('date')) {
        return [{
            fieldId: field.id,
            fieldName: field.name,
            fieldLabel: field.label,
            fieldType: field.type,
            value: addHours(startOfTomorrow(), 10)
        }]
    }  else if (field.type.includes('checkbox')) {
        return [{
            fieldId: field.id,
            fieldName: field.name,
            fieldLabel: field.label,
            fieldType: field.type,
            value: 'false'
        }]
    } else {
        return [{
            fieldId: field.id,
            fieldName: field.name,
            fieldLabel: field.label,
            fieldType: field.type,
            value: ''
        }]
    }
}
const createInitialFormValues = (entryValues: EntryValue[]): object => {
    return _.reduce(entryValues, (result, value) => {
        result[value.fieldName] = value.value
        return result
    }, {})
}

export const useEntryValue = (form: Form) => {
    const [entryValues, setEntryValues] = useState<EntryValue[]>([]);

    useEffect(() => {
        setEntryValues(createContents(form.fields))
    }, [form])

    
    const initialValues = createInitialFormValues(entryValues);

    const handleChangeEntryValue = (event, target: EntryValue): void => {
        // DatePicker用処理
        if (event.constructor === Date) {
            target.value = event;
        } else if (target.fieldType === 'checkbox') {
            target.value = event.target.checked.toString();
        } else {
            target.value = event.target.value;
        }
        const newState = _.cloneDeep(entryValues)
        const targetIndex = _.findIndex(entryValues, { fieldName: target.fieldName })
        newState[targetIndex] = target;
        setEntryValues(newState);
    }
    return [entryValues, initialValues ,handleChangeEntryValue] as const
}