// react
import React from 'react';

// material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// material-ui/icons
import PhoneIcon from '@material-ui/icons/Phone';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// api client
import { Store } from '../client/models';

import TimeRange from './TimeRange';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1rem',
    marginRight: 5,
  },
}));

const Header = ({ store }: { store: Store }) => {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      <Typography variant="h3" component="h1" gutterBottom>
        {store?.name}
      </Typography>
      <Typography variant="body1" color="secondary" gutterBottom>
        <div>
          <TimeRange label="平日" time={store?.businessTimeWeekday} />
          <TimeRange label="土日" time={store?.businessTimeSatSun} />
          <TimeRange label="祝日" time={store?.businessTimeHoliday} />
          
        </div>
        {!!store?.tel && (
          <div>
            <PhoneIcon className={classes.icon} />{store?.tel}
          </div>
        )}
      </Typography>
    </header>
  );
}

export default Header;
