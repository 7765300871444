import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ThanksPage from './pages/Thanks';
import StorePage from './pages/Store';

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <Routes>
        <Route path="/thanks/:key" element={<ThanksPage />}></Route>
        <Route path="/form/:key" element={<App />}></Route>
        <Route path="/store/:key" element={<StorePage />}></Route>
      </Routes>
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
