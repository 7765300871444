/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntryItem
 */
export interface EntryItem {
    /**
     * 
     * @type {number}
     * @memberof EntryItem
     */
    fieldId?: number;
    /**
     * 
     * @type {string}
     * @memberof EntryItem
     */
    fieldType: string;
    /**
     * 
     * @type {string}
     * @memberof EntryItem
     */
    fieldName: string;
    /**
     * 
     * @type {string}
     * @memberof EntryItem
     */
    fieldLabel: string;
    /**
     * 
     * @type {string}
     * @memberof EntryItem
     */
    value: string;
}

/**
 * Check if a given object implements the EntryItem interface.
 */
export function instanceOfEntryItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fieldType" in value;
    isInstance = isInstance && "fieldName" in value;
    isInstance = isInstance && "fieldLabel" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function EntryItemFromJSON(json: any): EntryItem {
    return EntryItemFromJSONTyped(json, false);
}

export function EntryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fieldId': !exists(json, 'field_id') ? undefined : json['field_id'],
        'fieldType': json['field_type'],
        'fieldName': json['field_name'],
        'fieldLabel': json['field_label'],
        'value': json['value'],
    };
}

export function EntryItemToJSON(value?: EntryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_id': value.fieldId,
        'field_type': value.fieldType,
        'field_name': value.fieldName,
        'field_label': value.fieldLabel,
        'value': value.value,
    };
}

