import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { StoreBusinessTime } from './../client';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '1rem',
    marginRight: 5,
  },
}));

const Time = ({ hours, minutes }: { hours: number | undefined, minutes: number | undefined }) => {
  if (hours === undefined) {
    return (null);
  }

  return (
    <>
      {hours.toString().padStart(2, '0')}
      :
      {minutes.toString().padStart(2, '0')}
    </>
  );
};

interface IProps {
  label: string;
  time: StoreBusinessTime;
}

const TimeRange = ({ label, time }: IProps) => {
  const classes = useStyles();

  if (Object.keys(time ?? {}).every(key => time[key] === undefined)) {
    return (null);
  }

  return (
    <div>
      <AccessTimeIcon className={classes.icon} />
      {label}
      <Time hours={time.fromHours} minutes={time.fromMinutes} />
      ～
      <Time hours={time.toHours} minutes={time.toMinutes} />
    </div>
  );
};

export default TimeRange;
