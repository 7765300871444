/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormField
 */
export interface FormField {
    /**
     * 
     * @type {number}
     * @memberof FormField
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    type: FormFieldTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof FormField
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof FormField
     */
    required: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormField
     */
    options?: Array<string>;
}


/**
 * @export
 */
export const FormFieldTypeEnum = {
    Name: 'name',
    Email: 'email',
    Address: 'address',
    Tel: 'tel',
    Date: 'date',
    Datetime: 'datetime',
    ReservationDateTime: 'reservation_date_time',
    Text: 'text',
    Textarea: 'textarea',
    Checkbox: 'checkbox',
    Radio: 'radio',
    Select: 'select'
} as const;
export type FormFieldTypeEnum = typeof FormFieldTypeEnum[keyof typeof FormFieldTypeEnum];


/**
 * Check if a given object implements the FormField interface.
 */
export function instanceOfFormField(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "required" in value;

    return isInstance;
}

export function FormFieldFromJSON(json: any): FormField {
    return FormFieldFromJSONTyped(json, false);
}

export function FormFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'name': json['name'],
        'label': json['label'],
        'position': json['position'],
        'required': json['required'],
        'options': !exists(json, 'options') ? undefined : json['options'],
    };
}

export function FormFieldToJSON(value?: FormField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'name': value.name,
        'label': value.label,
        'position': value.position,
        'required': value.required,
        'options': value.options,
    };
}

