// react
import React from 'react';

// material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// material-ui/icons
import PhoneIcon from '@material-ui/icons/Phone';

// api client
import { Store } from '../client/models';

import styles from "../App.module.scss"

const Footer = ({ store }: { store: Store }) => {
  return (
    <footer className={styles.footer}>
      {
        store?.entryFormUrl &&
        <div>
          <Link className={styles.footerButton} href={store.entryFormUrl}>予約する</Link>
        </div>
      }
    </footer>
  );
}

export default Footer;
