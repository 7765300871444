/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Form,
  PutFormsKeyRequest,
} from '../models';
import {
    FormFromJSON,
    FormToJSON,
    PutFormsKeyRequestFromJSON,
    PutFormsKeyRequestToJSON,
} from '../models';

export interface GetFormRequest {
    key: string;
}

export interface GetFormsRequest {
    storeId: string;
}

export interface PostFormsRequest {
    form?: Form;
}

export interface PutFormsKeyOperationRequest {
    key: string;
    putFormsKeyRequest?: PutFormsKeyRequest;
}

/**
 * 
 */
export class FormApi extends runtime.BaseAPI {

    /**
     * FrontEndからフォームを取得する
     * Get form
     */
    async getFormRaw(requestParameters: GetFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Form>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/forms/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormFromJSON(jsonValue));
    }

    /**
     * FrontEndからフォームを取得する
     * Get form
     */
    async getForm(requestParameters: GetFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Form> {
        const response = await this.getFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * フォームの一覧を取得(不要？)
     * List Forms
     */
    async getFormsRaw(requestParameters: GetFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling getForms.');
        }

        const queryParameters: any = {};

        if (requestParameters.storeId !== undefined) {
            queryParameters['store_id'] = requestParameters.storeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/forms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * フォームの一覧を取得(不要？)
     * List Forms
     */
    async getForms(requestParameters: GetFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getFormsRaw(requestParameters, initOverrides);
    }

    /**
     * Adminからフォームを作成する
     * Create Form
     */
    async postFormsRaw(requestParameters: PostFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/forms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormToJSON(requestParameters.form),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adminからフォームを作成する
     * Create Form
     */
    async postForms(requestParameters: PostFormsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postFormsRaw(requestParameters, initOverrides);
    }

    /**
     * Adminからのフォームの更新
     * 
     */
    async putFormsKeyRaw(requestParameters: PutFormsKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling putFormsKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/forms/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutFormsKeyRequestToJSON(requestParameters.putFormsKeyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adminからのフォームの更新
     * 
     */
    async putFormsKey(requestParameters: PutFormsKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putFormsKeyRaw(requestParameters, initOverrides);
    }

}
