/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreBusinessTime } from './StoreBusinessTime';
import {
    StoreBusinessTimeFromJSON,
    StoreBusinessTimeFromJSONTyped,
    StoreBusinessTimeToJSON,
} from './StoreBusinessTime';
import type { StoreHoliday } from './StoreHoliday';
import {
    StoreHolidayFromJSON,
    StoreHolidayFromJSONTyped,
    StoreHolidayToJSON,
} from './StoreHoliday';
import type { StoreRegularHoliday } from './StoreRegularHoliday';
import {
    StoreRegularHolidayFromJSON,
    StoreRegularHolidayFromJSONTyped,
    StoreRegularHolidayToJSON,
} from './StoreRegularHoliday';

/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    tel?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    prefecture?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    catchphrase?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    description?: string;
    /**
     * 
     * @type {StoreBusinessTime}
     * @memberof Store
     */
    businessTimeWeekday?: StoreBusinessTime;
    /**
     * 
     * @type {StoreBusinessTime}
     * @memberof Store
     */
    businessTimeSatSun?: StoreBusinessTime;
    /**
     * 
     * @type {StoreBusinessTime}
     * @memberof Store
     */
    businessTimeHoliday?: StoreBusinessTime;
    /**
     * 
     * @type {StoreRegularHoliday}
     * @memberof Store
     */
    storeRegularHoliday?: StoreRegularHoliday;
    /**
     * 
     * @type {Array<StoreHoliday>}
     * @memberof Store
     */
    storeHolidays?: Array<StoreHoliday>;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeAreaName?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemName1?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemValue1?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemName2?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemValue2?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemName3?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemValue3?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemName4?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemValue4?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemName5?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeItemValue5?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    entryFormUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeImage1Url?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeImage2Url?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeImage3Url?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeImage4Url?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    freeImage5Url?: string;
}

/**
 * Check if a given object implements the Store interface.
 */
export function instanceOfStore(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function StoreFromJSON(json: any): Store {
    return StoreFromJSONTyped(json, false);
}

export function StoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): Store {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'id': json['id'],
        'name': json['name'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'prefecture': !exists(json, 'prefecture') ? undefined : json['prefecture'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'catchphrase': !exists(json, 'catchphrase') ? undefined : json['catchphrase'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'businessTimeWeekday': !exists(json, 'business_time_weekday') ? undefined : StoreBusinessTimeFromJSON(json['business_time_weekday']),
        'businessTimeSatSun': !exists(json, 'business_time_sat_sun') ? undefined : StoreBusinessTimeFromJSON(json['business_time_sat_sun']),
        'businessTimeHoliday': !exists(json, 'business_time_holiday') ? undefined : StoreBusinessTimeFromJSON(json['business_time_holiday']),
        'storeRegularHoliday': !exists(json, 'store_regular_holiday') ? undefined : StoreRegularHolidayFromJSON(json['store_regular_holiday']),
        'storeHolidays': !exists(json, 'store_holidays') ? undefined : ((json['store_holidays'] as Array<any>).map(StoreHolidayFromJSON)),
        'freeAreaName': !exists(json, 'free_area_name') ? undefined : json['free_area_name'],
        'freeItemName1': !exists(json, 'free_item_name1') ? undefined : json['free_item_name1'],
        'freeItemValue1': !exists(json, 'free_item_value1') ? undefined : json['free_item_value1'],
        'freeItemName2': !exists(json, 'free_item_name2') ? undefined : json['free_item_name2'],
        'freeItemValue2': !exists(json, 'free_item_value2') ? undefined : json['free_item_value2'],
        'freeItemName3': !exists(json, 'free_item_name3') ? undefined : json['free_item_name3'],
        'freeItemValue3': !exists(json, 'free_item_value3') ? undefined : json['free_item_value3'],
        'freeItemName4': !exists(json, 'free_item_name4') ? undefined : json['free_item_name4'],
        'freeItemValue4': !exists(json, 'free_item_value4') ? undefined : json['free_item_value4'],
        'freeItemName5': !exists(json, 'free_item_name5') ? undefined : json['free_item_name5'],
        'freeItemValue5': !exists(json, 'free_item_value5') ? undefined : json['free_item_value5'],
        'entryFormUrl': !exists(json, 'entry_form_url') ? undefined : json['entry_form_url'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'freeImage1Url': !exists(json, 'free_image1_url') ? undefined : json['free_image1_url'],
        'freeImage2Url': !exists(json, 'free_image2_url') ? undefined : json['free_image2_url'],
        'freeImage3Url': !exists(json, 'free_image3_url') ? undefined : json['free_image3_url'],
        'freeImage4Url': !exists(json, 'free_image4_url') ? undefined : json['free_image4_url'],
        'freeImage5Url': !exists(json, 'free_image5_url') ? undefined : json['free_image5_url'],
    };
}

export function StoreToJSON(value?: Store | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'id': value.id,
        'name': value.name,
        'tel': value.tel,
        'zip': value.zip,
        'prefecture': value.prefecture,
        'city': value.city,
        'street': value.street,
        'catchphrase': value.catchphrase,
        'description': value.description,
        'business_time_weekday': StoreBusinessTimeToJSON(value.businessTimeWeekday),
        'business_time_sat_sun': StoreBusinessTimeToJSON(value.businessTimeSatSun),
        'business_time_holiday': StoreBusinessTimeToJSON(value.businessTimeHoliday),
        'store_regular_holiday': StoreRegularHolidayToJSON(value.storeRegularHoliday),
        'store_holidays': value.storeHolidays === undefined ? undefined : ((value.storeHolidays as Array<any>).map(StoreHolidayToJSON)),
        'free_area_name': value.freeAreaName,
        'free_item_name1': value.freeItemName1,
        'free_item_value1': value.freeItemValue1,
        'free_item_name2': value.freeItemName2,
        'free_item_value2': value.freeItemValue2,
        'free_item_name3': value.freeItemName3,
        'free_item_value3': value.freeItemValue3,
        'free_item_name4': value.freeItemName4,
        'free_item_value4': value.freeItemValue4,
        'free_item_name5': value.freeItemName5,
        'free_item_value5': value.freeItemValue5,
        'entry_form_url': value.entryFormUrl,
        'image_url': value.imageUrl,
        'free_image1_url': value.freeImage1Url,
        'free_image2_url': value.freeImage2Url,
        'free_image3_url': value.freeImage3Url,
        'free_image4_url': value.freeImage4Url,
        'free_image5_url': value.freeImage5Url,
    };
}

