/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Entry,
  GetFormsKeyEntries200Response,
} from '../models';
import {
    EntryFromJSON,
    EntryToJSON,
    GetFormsKeyEntries200ResponseFromJSON,
    GetFormsKeyEntries200ResponseToJSON,
} from '../models';

export interface GetFormsKeyEntriesRequest {
    key: string;
}

export interface PostFormsKeyEntriesRequest {
    key: string;
    entry?: Entry;
}

/**
 * 
 */
export class EntryApi extends runtime.BaseAPI {

    /**
     * Railsからフォームのエントリーを全て取得する
     * List Entries
     */
    async getFormsKeyEntriesRaw(requestParameters: GetFormsKeyEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFormsKeyEntries200Response>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling getFormsKeyEntries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/forms/{key}/entries`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFormsKeyEntries200ResponseFromJSON(jsonValue));
    }

    /**
     * Railsからフォームのエントリーを全て取得する
     * List Entries
     */
    async getFormsKeyEntries(requestParameters: GetFormsKeyEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFormsKeyEntries200Response> {
        const response = await this.getFormsKeyEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * FronEndからフォームの入力をする
     * Create Entry
     */
    async postFormsKeyEntriesRaw(requestParameters: PostFormsKeyEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling postFormsKeyEntries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/forms/{key}/entries`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntryToJSON(requestParameters.entry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * FronEndからフォームの入力をする
     * Create Entry
     */
    async postFormsKeyEntries(requestParameters: PostFormsKeyEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postFormsKeyEntriesRaw(requestParameters, initOverrides);
    }

}
