/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormField } from './FormField';
import {
    FormFieldFromJSON,
    FormFieldFromJSONTyped,
    FormFieldToJSON,
} from './FormField';
import type { Store } from './Store';
import {
    StoreFromJSON,
    StoreFromJSONTyped,
    StoreToJSON,
} from './Store';

/**
 * 
 * @export
 * @interface Form
 */
export interface Form {
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    key: string;
    /**
     * 
     * @type {number}
     * @memberof Form
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    name: string;
    /**
     * 
     * @type {Array<FormField>}
     * @memberof Form
     */
    fields: Array<FormField>;
    /**
     * 
     * @type {Store}
     * @memberof Form
     */
    store: Store;
    /**
     * 
     * @type {string}
     * @memberof Form
     */
    redirectUrl?: string;
}

/**
 * Check if a given object implements the Form interface.
 */
export function instanceOfForm(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "fields" in value;
    isInstance = isInstance && "store" in value;

    return isInstance;
}

export function FormFromJSON(json: any): Form {
    return FormFromJSONTyped(json, false);
}

export function FormFromJSONTyped(json: any, ignoreDiscriminator: boolean): Form {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'id': json['id'],
        'name': json['name'],
        'fields': ((json['fields'] as Array<any>).map(FormFieldFromJSON)),
        'store': StoreFromJSON(json['store']),
        'redirectUrl': !exists(json, 'redirect_url') ? undefined : json['redirect_url'],
    };
}

export function FormToJSON(value?: Form | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'id': value.id,
        'name': value.name,
        'fields': ((value.fields as Array<any>).map(FormFieldToJSON)),
        'store': StoreToJSON(value.store),
        'redirect_url': value.redirectUrl,
    };
}

