// react
import React from 'react';

// react-router
import { useParams } from 'react-router-dom';

// material-ui/core
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress'
import Slider from "react-slick";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import TimeRange from '../components/TimeRange';

// libraries
import * as _ from 'lodash';
import { useForm } from "../hooks/useForm";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../App.module.scss"
import "../style.css"

// react-helmet
import { Helmet } from 'react-helmet';

const weekOfDay = {
  sunday: "日",
  monday: "月",
  tuesday: "火",
  wednesday: "水",
  thursday: "木",
  friday: "金",
  saturday: "土",
  holiday: "祝",
};

const StorePage = () => {
  const { key } = useParams();
  const [form, loading] = useForm(key);
  const store = form.store
  const sliderSettings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Helmet title={store?.name + "の店舗ページ | 予約ドーン"}/>
      <CssBaseline />
      <Container className={styles.container} component="main" maxWidth="sm">
        <Header store={store} />
        {loading ? (
          <CircularProgress />
        ) : <>
        <div>{store?.catchphrase}</div>
          <div>
            <img className={styles.topImage} src={store?.imageUrl}></img>
          </div>
            <div className={styles.valueField} >{store?.description}</div>
          <h2>店舗詳細</h2>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={styles.tableHeader}>店舗名</TableCell>
                <TableCell>{store?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>電話番号</TableCell>
                <TableCell>{store?.tel}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>住所</TableCell>
                <TableCell>{store?.zip}{store?.prefecture}{store?.city}{store?.street}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>営業時間</TableCell>
                  <TableCell className={styles.valueField} >
                    <TimeRange label="平日" time={store?.businessTimeWeekday} />
                    <TimeRange label="土日" time={store?.businessTimeSatSun} />
                    <TimeRange label="祝日" time={store?.businessTimeHoliday} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>定休曜日</TableCell>
                  <TableCell className={styles.valueField} >
                    {
                      !!store?.storeRegularHoliday &&
                      <div>
                        {Object.keys(store.storeRegularHoliday).filter(holiday => store.storeRegularHoliday[holiday]).map(holiday => weekOfDay[holiday]).join(',')}
                      </div>
                    }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>定休日</TableCell>
                  <TableCell className={styles.valueField} >
                    <div>
                      {
                        store?.storeHolidays?.map(holiday => (
                          <div key={`${holiday.month}-${holiday.date}`}>
                            {holiday.month}月
                            {holiday.date}日
                          </div>
                        ))
                      }
                    </div>
                  </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className={styles.sliderContainer}>
            <Slider {...sliderSettings} >
              {
                store?.freeImage1Url &&
                <div>
                <img className={styles.sliderImg} src={store?.freeImage1Url}></img>
                </div>
              }
              {
                store?.freeImage2Url &&
                <div>
                <img className={styles.sliderImg} src={store?.freeImage2Url}></img>
                </div>
              }
              {
                store?.freeImage3Url &&
                <div>
                <img className={styles.sliderImg} src={store?.freeImage3Url}></img>
                </div>
              }
              {
                store?.freeImage4Url &&
                <div>
                <img className={styles.sliderImg} src={store?.freeImage4Url}></img>
                </div>
              }
              {
                store?.freeImage5Url &&
                <div>
                <img className={styles.sliderImg} src={store?.freeImage5Url}></img>
                </div>
              }
            </Slider>
          </div>
        <h2>{store?.freeAreaName}</h2>
        <Table>
          <TableBody>
            {
              store?.freeItemName1 && store?.freeItemValue1 &&
              <TableRow>
              <TableCell className={styles.tableHeader}>{store?.freeItemName1}</TableCell>
                  <TableCell className={styles.valueField} >{store?.freeItemValue1}</TableCell>
              </TableRow>
              
            }
                {
                  store?.freeItemName2 && store?.freeItemValue2 &&
                  <TableRow>
                    <TableCell className={styles.tableHeader}>{store?.freeItemName2}</TableCell>
                    <TableCell className={styles.valueField} >{store?.freeItemValue2}</TableCell>
                  </TableRow>

                }
                {
                  store?.freeItemName3 && store?.freeItemValue3 &&
                  <TableRow>
                    <TableCell className={styles.tableHeader}>{store?.freeItemName3}</TableCell>
                    <TableCell className={styles.valueField} >{store?.freeItemValue3}</TableCell>
                  </TableRow>

                }
                {
                  store?.freeItemName4 && store?.freeItemValue4 &&
                  <TableRow>
                    <TableCell className={styles.tableHeader}>{store?.freeItemName4}</TableCell>
                    <TableCell className={styles.valueField} >{store?.freeItemValue4}</TableCell>
                  </TableRow>
                }
                {
                  store?.freeItemName5 && store?.freeItemValue5 &&
                  <TableRow>
                    <TableCell className={styles.tableHeader}>{store?.freeItemName5}</TableCell>
                    <TableCell className={styles.valueField} >{store?.freeItemValue5}</TableCell>
                  </TableRow>
                }
          </TableBody>
        </Table>
        <Footer store={store} />
        </>
      }
      </Container>
    </>
  );
}

export default StorePage;
