/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreBusinessTime
 */
export interface StoreBusinessTime {
    /**
     * 
     * @type {number}
     * @memberof StoreBusinessTime
     */
    toMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreBusinessTime
     */
    toHours?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreBusinessTime
     */
    fromMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreBusinessTime
     */
    fromHours?: number;
}

/**
 * Check if a given object implements the StoreBusinessTime interface.
 */
export function instanceOfStoreBusinessTime(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StoreBusinessTimeFromJSON(json: any): StoreBusinessTime {
    return StoreBusinessTimeFromJSONTyped(json, false);
}

export function StoreBusinessTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreBusinessTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'toMinutes': !exists(json, 'to_minutes') ? undefined : json['to_minutes'],
        'toHours': !exists(json, 'to_hours') ? undefined : json['to_hours'],
        'fromMinutes': !exists(json, 'from_minutes') ? undefined : json['from_minutes'],
        'fromHours': !exists(json, 'from_hours') ? undefined : json['from_hours'],
    };
}

export function StoreBusinessTimeToJSON(value?: StoreBusinessTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'to_minutes': value.toMinutes,
        'to_hours': value.toHours,
        'from_minutes': value.fromMinutes,
        'from_hours': value.fromHours,
    };
}

