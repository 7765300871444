/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Form } from './Form';
import {
    FormFromJSON,
    FormFromJSONTyped,
    FormToJSON,
} from './Form';

/**
 * 
 * @export
 * @interface PutFormsKeyRequest
 */
export interface PutFormsKeyRequest {
    /**
     * 
     * @type {Form}
     * @memberof PutFormsKeyRequest
     */
    form: Form;
}

/**
 * Check if a given object implements the PutFormsKeyRequest interface.
 */
export function instanceOfPutFormsKeyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "form" in value;

    return isInstance;
}

export function PutFormsKeyRequestFromJSON(json: any): PutFormsKeyRequest {
    return PutFormsKeyRequestFromJSONTyped(json, false);
}

export function PutFormsKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutFormsKeyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form': FormFromJSON(json['form']),
    };
}

export function PutFormsKeyRequestToJSON(value?: PutFormsKeyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form': FormToJSON(value.form),
    };
}

