/* tslint:disable */
/* eslint-disable */
/**
 * doon-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@zenoffice.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntryItem } from './EntryItem';
import {
    EntryItemFromJSON,
    EntryItemFromJSONTyped,
    EntryItemToJSON,
} from './EntryItem';

/**
 * 
 * @export
 * @interface Entry
 */
export interface Entry {
    /**
     * 
     * @type {string}
     * @memberof Entry
     */
    readonly id?: string;
    /**
     * 
     * @type {number}
     * @memberof Entry
     */
    formId: number;
    /**
     * 
     * @type {Date}
     * @memberof Entry
     */
    conversionDateTime: Date;
    /**
     * 
     * @type {Array<EntryItem>}
     * @memberof Entry
     */
    contents: Array<EntryItem>;
}

/**
 * Check if a given object implements the Entry interface.
 */
export function instanceOfEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "formId" in value;
    isInstance = isInstance && "conversionDateTime" in value;
    isInstance = isInstance && "contents" in value;

    return isInstance;
}

export function EntryFromJSON(json: any): Entry {
    return EntryFromJSONTyped(json, false);
}

export function EntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Entry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'formId': json['form_id'],
        'conversionDateTime': (new Date(json['conversion_date_time'])),
        'contents': ((json['contents'] as Array<any>).map(EntryItemFromJSON)),
    };
}

export function EntryToJSON(value?: Entry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_id': value.formId,
        'conversion_date_time': (value.conversionDateTime.toISOString()),
        'contents': ((value.contents as Array<any>).map(EntryItemToJSON)),
    };
}

