// react
import React from 'react';

import * as holidayJp from '@holiday-jp/holiday_jp';

// material-ui/core
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

// material-ui/pickers
import {
  DatePicker,
  DateTimePicker,
} from 'formik-material-ui-pickers';

import {
  EntryValue
} from '../hooks/useEntryValue'

// formik
import { Field, useFormikContext } from 'formik'
import { TextField } from 'formik-material-ui';

// api client
import { FormFieldTypeEnum, FormField, Store } from '../client/models';
import _ from 'lodash'

export interface FormFieldProps {
  field: FormField,
  entryValues: EntryValue[],
  handler: Function,
  store: Store,
}

//TODO: EntryValue Propのフィールドへの渡し方を雑に全部渡さない
export const FormFieldComponent = ({ field, entryValues, handler, store }: { field: FormField, entryValues: EntryValue[], handler: Function, store: Store }) => {
  const props:FormFieldProps = {
    entryValues: _.filter(entryValues, { fieldId: field.id }),
    field,
    handler,
    store,
  }
  switch (props.field.type) {
    case FormFieldTypeEnum.Name: return <FormName {...props} />;
    case FormFieldTypeEnum.Email: return <FormEmail {...props} />;
    case FormFieldTypeEnum.Address: return <FormAddress {...props} />;
    case FormFieldTypeEnum.Tel: return <FormTel {...props} />;
    case FormFieldTypeEnum.Textarea: return <FormTextArea {...props} />;
    case FormFieldTypeEnum.Date: return <FormDate {...props} />;
    case FormFieldTypeEnum.Datetime: return <FormDateTime {...props} />;
    case FormFieldTypeEnum.ReservationDateTime: return <FormDateTime {...props} />;
    case FormFieldTypeEnum.Checkbox: return <FormCheckbox {...props} />;
    case FormFieldTypeEnum.Radio: return <FormSelect {...props} />;
    case FormFieldTypeEnum.Select: return <FormSelect {...props} />;
    default: return <FormText {...props} />
  }
}

const validate = (field, value, store) => {
  let error;
  error = validateRequired(field, value);
  if (field.name === 'zip'){
    error = validateZip(field, value);
  }
  if (field.type === FormFieldTypeEnum.Email){
    error = validateEmail(field, value);
  }
  if (field.type === FormFieldTypeEnum.Tel){
    error = validateTel(field, value);
  }
  if (field.type === FormFieldTypeEnum.ReservationDateTime){
    error = validatePastDateTime(field, value);
    error = error || validateStoreTime(field, value, store);
  }
  return error
}

const validateRequired = (field, value) => {
  if (field.required && !value) {
    return '必須の項目です'
  }
};

const validateEmail = (field, value) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return '不正なメールアドレスです';
  }
};

const validateZip = (field, value) => {
  if (!/^[0-9]{3}-?[0-9]{4}$/i.test(value)) {
    return '不正な郵便番号です';
  }
};

const validateTel = (field, value) => {
  if (!/^0[5789]0[-]?\d{4}[-]?\d{4}$/i.test(value)) {
    return  '不正な電話番号です';
  }
};

const validateDate = (field, value) => {
  if (value < Date.now()) {
    return  '過去の日付です';
  }
};

const validateDatetime = (field, value) => {
  if (value < Date.now()) {
    return  '過去の日時です';
  }
};

const validatePastDateTime = (field, value) => {
  if (value < Date.now()) {
    return  '過去の日時です';
  }
};

const validateStoreTime = (field, value, store) => {
  const generateDate = (hours, minutes) => {
    const date = new Date(value);
    date.setHours(hours);
    date.setMinutes(minutes);

    return date;
  };

  const validateTime = (businessTime, value) => {
    if (!businessTime) {
      return true;
    }
    if (businessTime.fromHours !== null && (value < generateDate(businessTime.fromHours, businessTime.fromMinutes))) {
      return false;
    }
    if (businessTime.toHours !== null && (value > generateDate(businessTime.toHours, businessTime.toMinutes))) {
      return false;
    }

    return true;
  };

  // 定休日
  if (store.storeHolidays.some(holiday => (holiday.month === (value.getMonth() + 1)) && (holiday.date === value.getDate()))) {
    return '定休日です';
  }

  // 定休曜日
  const day = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const dayMap = day.reduce((acc, cur, idx) => ({ ...acc, [idx]: cur }), {});
  if (store.storeRegularHoliday[dayMap[value.getDay()]]) {
    return '定休日です';
  }

  // 祝日
  if (holidayJp.isHoliday(value)) {
    if (store.storeRegularHoliday.holiday) {
      return '定休日です';
    }
    if (!validateTime(store.businessTimeHoliday, value)) {
      return '営業時間外です';
    }
  }

  // 時間
  switch (value.getDay()) {
    case 0:
    case 6:
      if (!validateTime(store.businessTimeSatSun, value)) {
        return '営業時間外です';
      }
      break;
    default:
      if (!validateTime(store.businessTimeWeekday, value)) {
        return '営業時間外です';
      }
      break;
  }
};

export const FormName = (props: FormFieldProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          onChange={(e) => props.handler(e, props.entryValues[0])}
          fullWidth
          label={`${props.field.label}: 姓`}
          margin="normal"
          name="last_name"
          required={props.field.required}
          value={props.entryValues[0]?.value}
          validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          onChange={(e) => props.handler(e, props.entryValues[1])}
          fullWidth
          label={`${props.field.label}: 名`}
          margin="normal"
          name="first_name"
          required={props.field.required}
          value={props.entryValues[1]?.value}
          validate={() => validate(props.field, props.entryValues[1]?.value, props.store)}
        />
      </Grid>
    </Grid>
  );
}

export const FormEmail = (props: FormFieldProps) => {
  return (
    <Field
      component={TextField}
      onChange={(e) => props.handler(e, props.entryValues[0])}
      fullWidth
      label={props.field.label}
      margin="normal"
      name={props.field.name}
      placeholder="mail@example.com"
      required={props.field.required}
      value={props.entryValues[0]?.value}
      validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
    />
  );
}

export const FormAddress = (props: FormFieldProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          onChange={(e) => props.handler(e, props.entryValues[0])}
          fullWidth
          label={`${props.field.label}: 郵便番号`}
          margin="normal"
          name="zip"
          required={props.field.required}
          value={props.entryValues[0]?.value}
          validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          onChange={(e) => props.handler(e, props.entryValues[1])}
          fullWidth
          label={`${props.field.label}: 都道府県`}
          margin="normal"
          name="prefecture"
          required={props.field.required}
          value={props.entryValues[1]?.value}
          validate={() => validate(props.field, props.entryValues[1]?.value, props.store)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          onChange={(e) => props.handler(e, props.entryValues[2])}
          fullWidth
          label={`${props.field.label}: 市区町村`}
          margin="normal"
          name="city"
          required={props.field.required}
          value={props.entryValues[2]?.value}
          validate={() => validate(props.field, props.entryValues[2]?.value, props.store)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          onChange={(e) => props.handler(e, props.entryValues[3])}
          fullWidth
          label={`${props.field.label}: 住所詳細`}
          margin="normal"
          name="street"
          required={props.field.required}
          value={props.entryValues[3]?.value}
          validate={() => validate(props.field, props.entryValues[3]?.value, props.store)}
        />
      </Grid>
    </Grid>
  );
}

export const FormTel = (props: FormFieldProps) => {
  return (
    <Field
      component={TextField}
      onChange={(e) => props.handler(e, props.entryValues[0])}
      fullWidth
      label={props.field.label}
      margin="normal"
      name={props.field.name}
      required={props.field.required}
      value={props.entryValues[0]?.value}
      validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
    />
  );
}

export const FormText = (props: FormFieldProps) => {
  return (
    <Field
      component={TextField}
      onChange={(e) => props.handler(e, props.entryValues[0])}
      fullWidth
      label={props.field.label}
      margin="normal"
      name={props.field.name}
      required={props.field.required}
      value={props.entryValues[0]?.value}
      validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
    />
  );
}

export const FormTextArea = (props: FormFieldProps) => {
  return (
    <Field
      component={TextField}
      onChange={(e) => props.handler(e, props.entryValues[0])}
      fullWidth
      label={props.field.label}
      margin="normal"
      multiline
      name={props.field.name}
      required={props.field.required}
      rows={4}
      value={props.entryValues[0]?.value}
      validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
    />
  );
}

export const FormDate = (props: FormFieldProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          component={DatePicker}
          onChange={(e) => props.handler(e, props.entryValues[0])}
          format="yyyy-MM-dd"
          fullWidth
          label={props.field.label}
          margin="normal"
          name={props.field.name}
          value={props.entryValues[0]?.value}
          validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
        />
      </Grid>
    </Grid>
  );
}

export const FormDateTime = (props: FormFieldProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          component={DateTimePicker}
          onChange={(e) => props.handler(e, props.entryValues[0])}
          format="yyyy-MM-dd HH:mm"
          fullWidth
          label={props.field.label}
          margin="normal"
          minutesStep={30}
          name={props.field.name}
          value={props.entryValues[0]?.value}
          validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
        />
      </Grid>
    </Grid>
  );
}

export const FormCheckbox = (props: FormFieldProps) => {
  const { setFieldValue } = useFormikContext();
  return (
    <div style={{ textAlign: 'left' }}>
      <label>
        <Field
          component={Checkbox}
          inputProps={{
            name: props.field.name,
          }}
          margin="normal"
          name={props.field.name}
          value={props.entryValues[0]?.value}
          onChange={(e) => props.handler(e, props.entryValues[0])}
          validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
        />
        {props.field.label}
      </label>
    </div>
  );
}

// export const FormRadio = ({ props }: { props: FormFieldProps }) => {
//   return (
//     <FormControl
//       component="fieldset"
//       fullWidth
//       margin="normal"
//       required={props.field.required}
//     >
//       <FormLabel
//         component="legend"
//         style={{ textAlign: 'left' }}
//       >
//         {field.label}
//       </FormLabel>
//       <Field
//         component={RadioGroup}
//         name={field.name}
//       >
//         {field.options.map((option, index) => (
//           <FormControlLabel
//             control={<Radio />}
//             key={index}
//             label={option}
//             value={option}
//           />
//         ))}
//       </Field>
//     </FormControl>
//   );
// }

export const FormSelect = (props: FormFieldProps) => {
  return (
    <Field
      component={TextField}
      onChange={(e) => props.handler(e, props.entryValues[0])}
      fullWidth
      inputProps={{
        name: props.field.name,
      }}
      label={props.field.label}
      margin="normal"
      name={props.field.name}
      value={props.entryValues[0]?.value}
      required={props.field.required}
      select
      validate={() => validate(props.field, props.entryValues[0]?.value, props.store)}
    >
      <MenuItem value=""><em>選択してください</em></MenuItem>
      {props.field.options?.map((option, index) => (
        <MenuItem key={index} value={option}>{option}</MenuItem>
      ))}
    </Field>
  );
}
