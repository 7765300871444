// react
import React from 'react';

// react-router
import { useNavigate, useParams } from 'react-router-dom';

// material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';

// material-ui/icon
import SendIcon from '@material-ui/icons/Send';

// material-ui/pickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// date-io
import DateFnsUtils from '@date-io/date-fns';

// formik
import {
  Form as FormikForm,
  Formik,
} from 'formik';

// client/apis
import {
  EntryApi,
  PostFormsKeyEntriesRequest,
} from './client/apis';

// client/models
import {
  EntryItem,
} from './client/models';

// components
import { FormFieldComponent } from './components/FormComponents';
import Header from './components/Header';

// libraries
import { formatDate } from './utils';
import { useForm } from "./hooks/useForm";
import { useEntryValue, EntryValue } from "./hooks/useEntryValue";
import * as _ from 'lodash';

// react-helmet
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const App = () => {
  const navigate = useNavigate();
  const { key } = useParams();
  const [form, loading] = useForm(key);
  const [entryValues, initialValues, handleChangeEntryValue] = useEntryValue(form);

  const createEntry = (entryValues: EntryValue[]): PostFormsKeyEntriesRequest => {
    const contents:EntryItem[] = _.map(entryValues, (content:EntryValue):EntryItem => {
      if (content.fieldType.includes('date')){
        const value:string = formatDate(content.value as Date);
        content.value = value
      }
      return content as EntryItem
    })
    return {
      key,
      entry: {
        formId: form.id,
        conversionDateTime: new Date(),
        contents: contents,
      },
    };
  };

  const classes = useStyles();
  return (
    <>
      <Helmet title={form?.store?.name + "の予約ページ | 予約ドーン"}/>
      <CssBaseline />
      <Container className={classes.root} component="main" maxWidth="sm">
        <Header store={form?.store} />
        {loading ? (
          <CircularProgress />
        ) : (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {form?.fields?.length && (
              <Formik
                initialValues={initialValues}
                onSubmit={(values, {setSubmitting}) => {
                  const entryApi = new EntryApi();
                  const entry = createEntry(entryValues);
                  console.log(form, entry);
                  entryApi.postFormsKeyEntries(entry)
                    .then(() => setSubmitting(false))
                    .then(() => {
                      if (form.redirectUrl) {
                        window.location.href = form.redirectUrl;
                      } else {
                        navigate('/thanks/' + key);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      setSubmitting(false);
                    })
                }}
              >
                {({ values, submitForm, isSubmitting }) => (
                  <FormikForm>
                    {form.fields.sort((a, b) => a.position - b.position).map((field, index) => (
                      <FormFieldComponent field={field} store={form.store} entryValues={entryValues} handler={handleChangeEntryValue} key={index} />
                    ))}
                    {isSubmitting && <LinearProgress />}
                    <Button
                      className={classes.submit}
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      onClick={submitForm}
                      size="large"
                      startIcon={<SendIcon />}
                      type="submit"
                      variant="contained"
                    >
                      送信
                    </Button>
                  </FormikForm>
                )}
              </Formik>
            )}
          </MuiPickersUtilsProvider>
        )}
      </Container>
    </>
  );
}

export default App;
