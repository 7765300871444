import {useState, useEffect} from "react";
import { Form } from '../client/models';
import { FormApi, GetFormRequest } from '../client/apis';

export const useForm = (key:string) => {
    const [loading, setLoading] = useState<boolean>(true);
    const initialForm: Form = {
        key,
        id: 0,
        name: '',
        fields: [],
        store: null,
    };
    
    const [form, setForm] = useState<Form>(initialForm);

    const createGetFormRequest = (key: string): GetFormRequest => {
        return {
            key,
        }
    };
    useEffect(() => {
        const getForm = async () => {
            const formApi = new FormApi();
            const result = await formApi.getForm(createGetFormRequest(key));
            setLoading(false);
            setForm(result);
            return result;
        };
        getForm();
        // eslint-disable-next-line
    }, []);
    return [form, loading] as const
}