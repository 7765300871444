import React from 'react';
import styles from '../App.module.scss'

// react-helmet
import { Helmet } from 'react-helmet';

const ThanksPage = () => {
  return(
    <>
      <Helmet
        title={"サンクスページ | 予約ドーン"}
        meta={[{name: 'robots', content: 'noindex'}]}
        />
      <div className={styles.thankspage_box}>
        <div className={styles.thankspage}>
          <h2>ご予約いただき、ありがとうございます。</h2>
          <p>ご予約を承りました。</p>
          <p>※ご予約はまだ完了しておりません。後ほど店舗からご連絡差し上げます。</p>
        </div>
      </div>
    </>
  )
}

export default ThanksPage;